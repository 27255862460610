import React from 'react';
import { Catchphrase } from '../../../components/typography';
import ColumnBuildingSVG from '../../../images/icons/column-building.svg';
import ProspectiveBorrowerSVG from '../../../images/icons/prospective-borrower.svg';
import WebsiteSVG from '../../../images/icons/website.svg';

const one = 'Own Up';
const two = 'Prospective Borrower';
const three = 'Lender';
export const ideas = [
  {
    Image: {
      svg: WebsiteSVG,
      imageName: 'website.svg',
      height: 144
    },
    data: {
      catchphrase: <Catchphrase>{one}</Catchphrase>,
      text: 'Own Up acquires and prequalifies prospective borrowers. We present personalized loan offers from our lender network in real-time.',
      label: one
    }
  },
  {
    Image: {
      svg: ProspectiveBorrowerSVG,
      imageName: 'prospective-borrower.svg',
      height: 144
    },
    data: {
      catchphrase: <Catchphrase>{two}</Catchphrase>,
      text: "The prospective borrower chooses their lender and loan program, and completes the lender's online app to lock their interest rate.",
      label: two
    }
  },
  {
    Image: {
      svg: ColumnBuildingSVG,
      imageName: 'column-building.svg',
      height: 144
    },
    data: {
      catchphrase: <Catchphrase>{three}</Catchphrase>,
      text: 'The lender processes, underwrites, and closes the loan. The fee to Own Up (40 bps) is due after the loan closes.',
      label: three
    }
  }
];
