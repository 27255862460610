import loadable from '@loadable/component';
import { ImageQuery, ImageWithContent } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React from 'react';
import { IntroImage } from '../components/intro-image';
import { Layout } from '../components/layout';
import { HowItWorks } from '../components/lender-network/how-it-works';
import {
  Highlight,
  ResponsivePrimaryText,
  ResponsiveSectionHeader
} from '../components/typography';
import GreenDotsBlob3SVG from '../images/green-dots-blob-3.svg';

const Compliance = loadable(() => import('../components/lender-network/compliance'), {
  resolveComponent: (components) => components.Compliance
});

const Divider = loadable(() => import('../components/divider'), {
  resolveComponent: (components) => components.Divider
});

const Spacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.Spacer
});

const ResponsiveSpacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.ResponsiveSpacer
});

const WhyOwnUpContent = loadable(() => import('../components/lender-network/why-own-up'), {
  resolveComponent: (components) => components.WhyOwnUpContent
});

export const query = graphql`
  {
    images: allFile(
      filter: { relativePath: { regex: "/lender-network//" }, extension: { ne: "svg" } }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

const IntroContent = () => (
  <>
    <ResponsiveSpacer />
    <ResponsiveSectionHeader variant="title">
      Increase loan volume and <Highlight>improve profitability</Highlight>
    </ResponsiveSectionHeader>
    <ResponsivePrimaryText>
      An exclusive real-time customer acquisition platform for mortgage lenders
    </ResponsivePrimaryText>
    <ResponsiveSpacer />
  </>
);

const AboutContent = () => (
  <>
    <ResponsiveSpacer />
    <ResponsiveSectionHeader>About Own Up</ResponsiveSectionHeader>
    <ResponsivePrimaryText>
      <p>
        Own Up has built the mortgage industry&apos;s first managed marketplace comprised of an
        exclusive group of retail lenders. We attract highly qualified prospective borrowers and
        provide unprecedented access to mortgage lenders that meet the highest standards for
        customer service.
      </p>
      <p>
        We use technology to create a modern home financing experience for borrowers that reduces
        anxiety and leads to better financial outcomes, while increasing loan volume and
        profitability for our lender partners.
      </p>
    </ResponsivePrimaryText>
  </>
);

export const LenderNetwork = ({ data }: { data: ImageQuery }) => {
  const lenderNetworkIntroImage = <IntroImage imageData={data} imageName="own-up-on-devices.png" />;
  const whyOwnUpImage = <IntroImage imageData={data} imageName="liz-and-matt.png" />;
  const aboutImage = <IntroImage imageData={data} imageName="mike-and-patrick-in-park.png" />;

  return (
    <Layout>
      <ImageWithContent
        backgroundImage={GreenDotsBlob3SVG}
        Image={lenderNetworkIntroImage}
        label="intro"
        sectionStyle={{ margin: '0 30px' }}
      >
        <IntroContent />
      </ImageWithContent>
      <Divider />
      <HowItWorks />
      <Divider />
      <ResponsiveSpacer />
      <ImageWithContent
        backgroundImage={GreenDotsBlob3SVG}
        Image={whyOwnUpImage}
        imagePosition="right"
        label="Why use Own Up"
      >
        <WhyOwnUpContent />
      </ImageWithContent>
      <Spacer />
      <Compliance />
      <Spacer />
      <ImageWithContent backgroundImage={GreenDotsBlob3SVG} Image={aboutImage} label="About Own Up">
        <AboutContent />
      </ImageWithContent>
      <Spacer />
    </Layout>
  );
};

export default LenderNetwork;
