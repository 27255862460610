import { ValueProps } from '@rategravity/marketing-components';
import React from 'react';
import { ideas } from '../../../data/content/lender-network/how-it-works';
import { metadata } from '../../../data/metadata/images';
import { ValuePropsWrapper } from '../../wrappers';

export const HowItWorks = () => (
  <ValuePropsWrapper>
    <ValueProps
      valueProps={ideas}
      metadata={metadata}
      header="How It Works"
      ariaLabel="How It Works"
    />
  </ValuePropsWrapper>
);
